import $ from "jquery";

export function init() {
	$(window).on("scroll", function () {
		if ($(window).scrollTop() > 0) {
			$(".main-header").addClass("bg-primary-100 shadow-header");
			$(".main-logo").removeClass('sm:w-40').addClass("sm:w-20");
		} else {
			//remove the background property so it comes transparent again (defined in your css)
			$(".main-header").removeClass("bg-primary-100 shadow-header");
			$(".main-logo").removeClass('sm:w-20').addClass("sm:w-40");
		}
	});

	$(document).on('click', '.menu-w-children', function(e) {
		var target = $('#' + $(this).data('target'));
		console.log(target);
		if (target.length) {
			e.preventDefault();
			console.log(target.attr('id'));
			if(target.hasClass('hidden')) {
				$('.header-extend-menu').addClass('hidden');
				if ((target.attr('id') !== 'search') && (target.attr('id') !== 'mob-search')) {
					$(this).find('svg').addClass('rotate-180');
				} else if (target.attr('id') == 'search') {
					$('#search-main').focus();
				} else if (target.attr('id') == 'mob-search') {
					$('#search-mob').focus();
				}
				$(target).removeClass('hidden');
			} else {
				$(target).addClass('hidden');
				$(this).find('svg').removeClass('rotate-180');
			}
			return false;
		}
	});
	
	$(document).on('click', '#mobile-menu-button', function(e) {
		$('#mobile-overlay').removeClass('hidden');
		$('.mobile-menu').removeClass('translate-x-full');
	});

	$(document).on('click', '#mobile-overlay, #mobile-menu-close-button', function(e) {
		$('#mobile-overlay').addClass('hidden');
		$('.mobile-menu').addClass('translate-x-full');
	});

	$(window).click(function () {
		$('.header-extend-menu').addClass('hidden');
	});

	$('.header-extend-menu').click(function (event) {
		event.stopPropagation();
	});
}